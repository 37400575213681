.container {
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    height: 100%;
}

.emailSection {
    margin-top: 20px;
}

.primaryEmailSection {
    margin-bottom: 20px;
}

.separator {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid #ccc;
}


.addEmailSection {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.addEmailSection .addEmailInput {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    width: 50%;
}

.addEmailButton {
    padding: 2px 6px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 3px;
    cursor: pointer;
    font-size: 10px;
}

.alternativeEmailActions {
    display: flex;
    column-gap: 3px;
}

.addEmailButton:hover {
    background-color: #0056b3;
}

.emailList {
    list-style: none;
    padding: 0;
}

.emailListItem {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}

.setAsPrimaryButton {
    padding: 2px 6px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 3px;
    cursor: pointer;
    font-size: 10px;
}

.removeAlternativeButton  {
    padding: 2px 6px;
    border: none;
    background-color: #dc3545;
    color: white;
    border-radius: 3px;
    cursor: pointer;
    font-size: 10px;
}