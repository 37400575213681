.container {
    /* display: flex; */
    flex-direction: column;
    height: 100%;
    background-color: var(--colour-MAIN_BLUE);
    overflow: scroll;
    padding-bottom: 16rem;
}

.content {
    margin-top: 3rem;
    flex: 1;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
}

.navbar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--colour-MAIN_BLUE);
    display: grid;
    grid-auto-flow: column;
    align-items: center;
}

.navItem {
    /* background-color: var(--colour-SCREENFREE_BLUE); */
    border-radius: 10px;
    padding: 0.5rem 1rem;
    transition: background-color 0.3s ease;
    height: 15rem;
    text-align: center;
    display: flex;
    align-items: center;
    border: 1px solid var(--colour-TRUE_WHITE);
    font-size: 2rem;
    cursor: pointer;
    text-decoration: none;
    color: var(--colour-TRUE_WHITE);
    display: flex;
    align-items: center;
    justify-content: center;
}

.navItem:hover {
    background-color: var(--colour-LIGHT_BLUE);
}

.navItem:active {
    background-color: var(--colour-MAIN_BLUE);
}

.dashboard_home {
    color: var(--colour-TRUE_WHITE);
    text-decoration: none;
    align-self: center;
    justify-self: center;
}

.header {
    font-family: "BigNoodleTitling Regular";
    font-size: 5rem;
    margin: 0;
    text-align: right;
    color: var(--colour-TRUE_WHITE);
    display: grid;
    grid-auto-flow: column;
    align-content: center;
    column-gap: 2rem;
}

.header>h1 {
    font-family: "BigNoodleTitling Regular";
    font-size: 5rem;
    margin: 0;
    text-align: right;
    color: var(--colour-TRUE_WHITE);
    display: grid;
    grid-auto-flow: column;
    align-content: center;
}

.active {
    background-color: var(--colour-LIGHT_BLUE);
}

.refreshButton {
    /* margin: 1rem; */
    /* padding: 0.5rem 1rem; */
    /* width: 10rem; */
    /* height: 5rem; */
    align-self: center;
    /* border-radius: 10px; */
    /* background-color: var(--colour-TRUE_WHITE); */
    color: var(--colour-TRUE_WHITE);
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    justify-self: start;
}