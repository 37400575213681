/* TicketItem.module.css */
.ticketItem {
  border: 1px solid gray;
  padding: 10px;
  margin-bottom: 10px;
}

.ticketItem h2 {
  font-size: 1.2em;
  margin: 0;
}

.ticketItem p {
  margin: 0;
}

.ticketItem button {
  margin-top: 10px;
  margin-right: 10px;
}

.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.8em;
  cursor: pointer;
}

.button_close {
  background-color: #f44336;
}

.button_open {
  background-color: #4caf50;
}

.ticketDescription {
  overflow: auto; 
  white-space: nowrap; 
  max-width: 100%; 
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ticketDescription::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ticketDescription {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}