.modalBody {
  overflow-y: auto;
  height: 100%;
}

.modalHeader {
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
}

.modal {
  background: var(--colour-GREY_2);
  border-radius: 15px;
  padding: 15px;
  /* Animation to be handled in JSX */
  width: 75vw;
  height: 75vh;
  overflow-y: hidden;
  padding-bottom: 50px;

}

.modalOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.title {
  color: var(--colour-primary);
  font-size: 30px;
  font-family: "BigNoodleTitling Oblique";
  display: inline-block;
}

.modalTitleContainer {
  display: flex;
  justify-content: space-between;
}

.closeSymbol {
  color: var(--colour-primary);
  cursor: pointer;
  align-self: center;
  font-size: 10px;
  padding: 5px 7px;
  border: solid var(--colour-primary) 1px;
  border-radius: 50%;
  text-transform: uppercase;
  transition: all 0.2s;
}

.closeSymbol:hover {
  color: var(--colour-RED);
  border: solid var(--colour-RED) 1px;
}
