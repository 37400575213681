.statsContainer {
    padding: 1rem;
    background-color: var(--colour-BLUE_LIGHT_2);
    color: var(--colour-BLACK);
    border-radius: 1rem;
    max-width: 800px;
    width: 100%;
    margin: auto;
  }
  
  .averageStats, .robotStats {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--colour-GREY_3);
    padding: 0.5rem;
    margin: 1rem 0;
    border-radius: 0.5rem;
  }
  
  .no_data {
    color: var(--colour-WHITE);
    text-align: center;
  }

  .statsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  .statsHeaderButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .statsHeaderButton {
    background-color: var(--colour-BLUE_LIGHT_2);
    color: var(--colour-BLACK);
    border: 1px solid var(--colour-GREY_3);
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin: 0 0.5rem;
    cursor: pointer;
  }

  .active {
    background-color: var(--colour-BLUE_LIGHT_1);
  }