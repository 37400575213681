.conversation {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin: 20px 0;
  background-color: #f9f9f9;
  max-width: 100%;
}

.conversation h2 {
  margin-top: 0;
}

.conversation h3 {
  margin-bottom: 10px;
}

.messages {
  display: flex;
  flex-direction: column;
}

.message {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.sent {
  background-color: #e6ffed;
}

.received {
  background-color: #ffe6e6;
}

.date {
  display: block;
  margin-top: 10px;
  font-size: 0.8em;
  color: #666;
}

.text {
  white-space: break-spaces;
  overflow-x: auto;
}
