.robotContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: var(--colour-BLUE_LIGHT_2);
    color: var(--colour-BLACK);
    border-radius: 1rem;
    max-width: 600px;
    width: 100%;
    align-self: center;
    justify-self: center;
}

.robot {
    border: 1px solid var(--colour-GREY_3);
    padding: 0.5rem;
    margin: 1rem 0;
    border-radius: 0.5rem;
    display: grid;
    width: 100%;
}

.robotItem {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;
    margin: 1rem 0;
    border-radius: 0.5rem;
}

.button_cancel {
    padding: 0.5rem 1rem;
    background-color: var(--colour-RED);
    color: var(--colour-TRUE_WHITE);
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    justify-self: center;
}

.button_add {
    padding: 0.5rem 1rem;
    background-color: var(--colour-SCREENFREE_GREEN);
    color: var(--colour-TRUE_WHITE);
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    justify-self: center;
}

.robotButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}