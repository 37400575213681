@font-face {
  font-family: 'BigNoodleTitling Regular';
  font-style: normal;
  font-weight: normal;
  src: local('BigNoodleTitling Regular'),
    url('../assets/fonts/BigNoodleTitling.ttf') format('truetype');
}

@font-face {
  font-family: 'BigNoodleTitling Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('BigNoodleTitling Oblique'),
    url('../assets/fonts/BigNoodleTitling-Oblique.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Regular';
  font-weight: normal;
  src: url('../assets/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Bold';
  font-weight: bold;
  src: url('../assets/fonts/Robotical.ttf') format('truetype');
}

p, div {
  font-family: "Lato Regular";
}

h1, h2, h3, h4, h5, h6 {
  font-family: "BigNoodleTitling Regular";
}