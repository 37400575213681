.plot_figure {
  height: 250px;
}

.question_mark_wrapper {
  width: 15px;
  height: 15px;
  display: inline-block;
}

.title_container {
  display: flex;
  justify-content: space-between;
}

.title {
  margin: 5px;
  text-transform: uppercase;
  color: white;
  font-weight: 100;
  font-family: "BigNoodleTitling Oblique";
  display: inline-block;
}

.maximise_plot {
  color: white;
  cursor: pointer;
  align-self: center;
  font-size: 18px;
  padding: 5px 7px;
  border-radius: 50%;
  text-transform: uppercase;
  font-family: "Lato Regular";
  transition: all 0.2s;
}
.maximise_plot:hover {
  color: var(--colour-SCREENFREE_BLUE);
}

.InModalPlotDescription {
  color: white;
  font-family: "Lato Regular";
  font-size: 18px;
}

.container {
  display: grid;
  grid-template-rows: min-content 1fr;
  width: 100%;
  justify-self: center;
  border-radius: 5px;
  background-color: var(--colour-BLACK);
  padding-bottom: 5px;
}
