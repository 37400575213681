.statsContainer {
    padding: 1rem;
    background-color: var(--colour-BLUE_LIGHT_2);
    color: var(--colour-BLACK);
    border-radius: 1rem;
    max-width: 600px;
    width: 100%;
    margin: auto;
  }
  
  .averageStats, .robotStats {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--colour-GREY_3);
    padding: 0.5rem;
    margin: 1rem 0;
    border-radius: 0.5rem;
  }
  