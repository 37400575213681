.profileContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: var(--colour-BLUE_LIGHT_2);
    color: var(--colour-BLACK);
    border-radius: 1rem;
    max-width: 600px;
    width: 100%;
    align-self: center;
    justify-self: center;
    max-height: 200px;
    overflow: auto;
}

.profileItem {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 1rem 0;
}

.button {
    display: inline-block;
    color: var(--colour-TRUE_WHITE);
    background-color: var(--colour-SCREENFREE_BLUE);
    padding: 0.5rem 1rem;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s ease;
    border: none;
}

.button:hover {
    background-color: var(--colour-LIGHT_BLUE);
}

.button:active {
    background-color: var(--colour-MAIN_BLUE);
}
