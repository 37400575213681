.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--colour-MAIN_BLUE);
    color: var(--colour-WHITE);
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    padding: 1rem;
    background-color: var(--colour-TRUE_WHITE);
    color: var(--colour-BLACK);
    border-radius: 10px;
}

.label {
    margin-bottom: 1rem;
}

.input {
    margin-bottom: 1rem;
    padding: 0.5rem;
    width: 20rem;
    border-radius: 5px;
    border: 1px solid var(--colour-GREY_3);
}

.button {
    padding: 0.5rem 1rem;
    color: var(--colour-TRUE_WHITE);
    background-color: var(--colour-SCREENFREE_BLUE);
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button_disabled {
    padding: 0.5rem 1rem;
    color: var(--colour-TRUE_WHITE);
    background-color: var(--colour-GREY_3);
    border: none;
    border-radius: 5px;
    cursor: not-allowed;
}

.logo {
    margin-bottom: 5rem;
}