.checkboxContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.label {
  margin-right: 8px;
  font-size: 16px;
  color: #333;
}

.checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
